/* eslint-disable no-restricted-syntax */
import Cookies from 'js-cookie';
import axios from '@shared/config/baseAPI';

// const userToken = Cookies.get('token'); // token from cookies

const deviceId = Cookies.get('deviceId'); // change this with your device id

export const userLogout = async (_token) => {
  try {
    return await axios.post('/site/login-out', null, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${_token}` } });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const userOnRender = async () => {
  try {
    return await axios.post('/user/profile', null, { headers: { 'device-id': `${deviceId}`, 'x-kumu-auth': `${Cookies.get('token')}` } });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const oneTimeAuth = async () => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/charge/one-time-auth`,
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Device-Type': `${Cookies.get('Device-Type')}`,
          'Device-Id': `${Cookies.get('Device-Id')}`,
          'Version-Code': `${Cookies.get('Version-Code')}`,
          'X-kumu-Token': `${Cookies.get('X-kumu-Token')}`,
          'X-kumu-UserId': `${Cookies.get('X-kumu-UserId')}`,
        },
      },
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
