import React from 'react';
import ReactDOM from 'react-dom';
import clevertap from 'clevertap-web-sdk';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

// google analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

ReactGA.event({
  category: 'User',
  action: 'Exists',
});

// clevertap
// Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ optOut: false });
// Set the flag to true, if the user agrees to share their IP data
clevertap.privacy.push({ useIP: true });
// Replace with values applicable to you. Refer below
clevertap.init(process.env.REACT_APP_CLEVERTAP);
clevertap.spa = true;

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<BrowserRouter><App /></BrowserRouter>, rootElement);
} else {
  ReactDOM.render(
    <BrowserRouter><App /></BrowserRouter>,
    rootElement,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
